<template>
  <div class="reset-password">
    <div class="reset-password__form">
      <div class="logo">
        <img src="@/assets/img/eden_logo.svg" alt="Eden" />
      </div>
      <el-form
        :model="emailForm"
        label-position="top"
        ref="emailForm"
        class="fadeIn"
      >
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item label="Email" prop="email" :rules="validateEmail()">
              <el-input
                type="text"
                autofocus
                v-model="emailForm.email"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="is-flex justify-center">
          <el-button :loading="sendingCode" type="primary" @click="sendCode"
            >Send Code</el-button
          >
        </div>
        <div class="mt-10 is-flex justify-center">
          <el-button type="text" @click="$router.push({ name: 'login' })"
            >Back to login</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import auth from "@/requests/auth";

export default {
  name: "ForgotPassword",
  data() {
    return {
      emailForm: {
        email: "",
      },
      sendingCode: false,
    };
  },
  methods: {
    sendCode() {
      this.$refs.emailForm.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.emailForm.email = this.emailForm.email.toLowerCase();
        this.sendPasswordCode();
        return true;
      });
    },
    resendCode() {
      this.sendPasswordCode();
    },

    sendPasswordCode() {
      this.sendingCode = true;

      auth
        .sendResetCode(this.emailForm)
        .then((response) => {
          this.sendingCode = false;
          if (response.data.status) {
            localStorage.setItem(
              "eden-password-reset-email",
              this.emailForm.email
            );
            this.$message.success(response.data.message);
            this.$router.push({ name: "reset-password" });
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.sendingCode = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.reset-password {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    height: 100px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__form {
    width: 80%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .el-form {
      width: 100%;
    }

    .password-reset {
      margin-top: 20px;
    }
    .link {
      cursor: pointer;
    }
  }
}
</style>
